import React, { useState } from "react";
import Chartkick from "react-chartkick";
import { Chart } from "chart.js";
import { BarChart } from "react-chartkick";

import PageTitle from "../../../../utilities/page-title/page-title";
import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController,
} from "chart.js";

import { AiOutlineInfo } from "react-icons/ai";
import styles from "./gross-sales.module.scss";

function formatISODateInUTC(isoDate) {
  const date = new Date(isoDate);
  const options = {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };
  const formattedDate = new Intl.DateTimeFormat("en-GB", options)
    .format(date)
    .replace(",", "");
  const amPmFormatted = formattedDate.replace(/\s?(am|pm)$/i, (match) =>
    match.toUpperCase()
  );

  return amPmFormatted;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  BarController
);
Chartkick.addAdapter(ChartJS);

const GrossSalesBarChart = ({ data, startDate, endDate, isLoading }) => {
  const { id, name, total, values, info } = data;

  const grossSalesData = {
    Today: values.today,
    Yesterday: values.yesterday,
    "This Week": values.thisWeek,
    "This Month": values.thisMonth,
  };

  const maxValue = Math.max(...Object.values(grossSalesData));
  const [showTooltip, setShowTooltip] = useState(false);
  const formattedValue = `$${total.toFixed(2)}`;
  const formattedStart = formatISODateInUTC(startDate);
  const formattedEnd = formatISODateInUTC(endDate);

  return (
    <div className={styles.graph_wrapper}>
      <div className={styles.chart_wrapper}>
        <PageTitle name={"Gross Sales"} className={styles.subtitle} />
        {isLoading && <BackdropLoading />}
        <BarChart
          data={grossSalesData}
          id={id}
          messages={{ empty: "No Records available." }}
          prefix="$"
          thousands=","
          round={2}
          colors={["#ED5950"]}
          max={maxValue}
        />
      </div>

      <div className={styles.time_period}>
        <div className={styles.title_wrapper}>
          <span className={styles.title}>
            {`${formattedStart} - ${formattedEnd} ${name}`}{" "}
            {info && (
              <span
                className={styles.info_icon}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <AiOutlineInfo />
                {showTooltip && (
                  <div className={styles.info_tooltip}>{info}</div>
                )}
              </span>
            )}
          </span>
        </div>
        <div className={styles.value}>{formattedValue}</div>
      </div>
    </div>
  );
};

export default GrossSalesBarChart;
