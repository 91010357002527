import dashboardData from "./dashboard-data.json";
import dashboardData08112024 from "./dashboard-data-12-11-2024.json";
import dashboardData07112024 from "./dashboard-data-13-11-2024.json";

const metricsStore = (() => {
  const dashboard = dashboardData.data;
  const dashboard08 = dashboardData08112024.data;
  const dashboard07 = dashboardData07112024.data;

  const getMetrics = (startDate, endDate) => {
    if (startDate && startDate === "2024-11-13T00:00:00.000Z") {
      return dashboard08;
    } else if (startDate && startDate === "2024-11-12T00:00:00.000Z") {
      return dashboard07;
    } else {
      return dashboard;
    }
  };
  return {
    getMetrics,
  };
})();

export default metricsStore;
