import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../../context/context';
import { updateExistingCustomer, fetchCustomer } from '../../../redux/customersSlice';

import clsx from 'clsx';

import PageTitle from '../../utilities/page-title/page-title';
import Input from '../../utilities/input/input';
import Button from '../../utilities/button/button';
import BackdropLoading from '../../utilities/backdrop-loading/backdrop-loading';
import DynamicFormFields from '../../utilities/dynamic-form-fields/dynamic-form-fields ';

import AddressForm from './forms/addresses/adrdess-form';
import PhonesForm from './forms/phones/phones-form';

import { registrationSchema } from './validation';
import { isObjectEmpty, mergeById } from './utils';

import styles from './customers.module.scss';

const valueConverter = (value, type) => {
  return type === 'number' ? parseFloat(value) : value;
};

const getCustomer = (customers, id) => {
  return customers.find((item) => item.id === parseFloat(id));
};

const EditCustomer = () => {
  const { id } = useParams();
  const { token } = useAppContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const customers = useSelector((state) => state.customers.customers);
  const customersStatus = useSelector((state) => state.customers.status);
  const currentCustomer = useSelector((state) => state.customers.currentCustomer);
  const [customer, setCustomer] = useState(getCustomer(customers, id) || currentCustomer);

  const [errors, setErrors] = useState({});

  const statusLoading = customersStatus === 'loading';

  useEffect(() => {
    if (customersStatus === 'idle') {
      dispatch(fetchCustomer({ id, token }));
    }
  }, [dispatch, token, customersStatus, id]);

  useEffect(() => {
    if (isObjectEmpty(customer)) {
      setCustomer(currentCustomer);
    }
  }, [customer, currentCustomer]);

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setCustomer((prev) => ({
      ...prev,
      [name]: valueConverter(value, type),
    }));
  };

  const handleCancel = () => {
    navigate('/customers');
  };

  const handleSaveChanges = async () => {
    const result = registrationSchema.safeParse(customer);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      setErrors({});

      try {
        await dispatch(updateExistingCustomer({ id, updatedDetails: customer, token })).unwrap();
        navigate('/customers');
      } catch {}
    }
  };

  const handlePhoneChange = useCallback((fields) => {
    handleInputChange({ target: { name: 'phones_attributes', value: fields } });
  }, []);

  const handleAddressChange = useCallback((fields) => {
    setCustomer((prev) => ({ ...prev, addresses_attributes: mergeById(prev.addresses, fields) }));
  }, []);

  return (
    <section className={styles.customer_edit_section}>
      <div className={styles.content}>
        <PageTitle name={'Edit Customer'} />
        <div className={styles.form_container}>
          <div className={styles.fields}>
            {statusLoading ? <BackdropLoading /> : null}
            <div className={clsx(styles.name_fields, styles.block)}>
              <div className={styles.field}>
                <Input
                  label={'First name'}
                  value={customer?.first_name}
                  onChange={handleInputChange}
                  name={'first_name'}
                  required={true}
                  errors={errors.first_name?._errors}
                />
              </div>
              <div className={styles.field}>
                <Input
                  label={'Last name'}
                  value={customer?.last_name}
                  onChange={handleInputChange}
                  name={'last_name'}
                  errors={errors.last_name?._errors}
                />
              </div>
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <DynamicFormFields
                value={customer?.phones_attributes}
                label={'Phone Numbers'}
                fieldItem={PhonesForm}
                fieldType={'text'}
                fieldProps={{ style: { margin: '0 auto' } }}
                maxFields={1}
                addButtonTitle={'Add Phone'}
                onChange={handlePhoneChange}
                className={styles.phone_container}
                bottomLine={true}
              />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <Input
                label={'Email Address'}
                value={customer?.email}
                onChange={handleInputChange}
                name={'email'}
                required={true}
                errors={errors.email?._errors}
              />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <DynamicFormFields
                value={customer?.addresses_attributes}
                label={'Delivery Address'}
                fieldItem={AddressForm}
                fieldType={'text'}
                fieldProps={{ style: { margin: '0 auto' } }}
                addButtonTitle={'Add Address'}
                onChange={handleAddressChange}
                bottomLine={true}
                errors={errors?.addresses_attributes}
              />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <DynamicFormFields
                label={'Billing Address'}
                fieldItem={AddressForm}
                fieldType={'text'}
                fieldProps={{ style: { margin: '0 auto' } }}
                addButtonTitle={'Add Address'}
                onChange={handleAddressChange}
                bottomLine={true}
                errors={errors?.addresses_attributes}
              />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <Input label={'Company'} value={customer?.company} onChange={handleInputChange} name={'company'} />
            </div>
            <div className={clsx(styles.field, styles.block)}>
              <Input label={'Birthday'} value={customer?.birth_date} onChange={handleInputChange} name={'birth_date'} />
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              className={styles.button}
              disabled={statusLoading}
              title={'Update Customer'}
              onClick={handleSaveChanges}
            />
            <Button
              className={styles.button}
              variant={'grey'}
              disabled={statusLoading}
              title={'Cancel'}
              onClick={handleCancel}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditCustomer;
