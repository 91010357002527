import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getWastageReasons,
  getWastageReason,
  addWastageReason,
  updateWastageReason,
  deleteWastageReason,
  reorderWastageReason,
} from '../api/wastage_reason';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchWastages = createAsyncThunk('wastageReason/getWastageReasons', async ({ token }) => {
  const response = await getWastageReasons(token);
  return response.data;
});

export const fetchWastage = createAsyncThunk('wastageReason/getWastageReason', async ({ id, token }) => {
  const response = await getWastageReason(id, token);
  return response.data;
});

export const addWastage = createAsyncThunk(
  'wastageReason/addWastageReason',
  async ({ newWastageDetails, token }, { dispatch }) => {
    try {
      const response = await addWastageReason(newWastageDetails, token);
      dispatch(
        addNotification({
          message: 'Wastage reason successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create wastage reason, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingWastage = createAsyncThunk(
  'wastageReason/updateWastageReason',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updateWastageReason(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Wastage reason successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update wastage reason, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeWastage = createAsyncThunk(
  'wastageReason/deleteWastageReason',
  async ({ id, token }, { dispatch }) => {
    try {
      await deleteWastageReason(id, token);
      dispatch(
        addNotification({
          message: 'Wastage reason successfully deleted',
          status: 'succeeded',
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to delete wastage reason, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const reorderWastage = createAsyncThunk(
  'wastageReason/reorderWastage',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await reorderWastageReason(id, updatedDetails, token);
      dispatch(fetchWastages({ token }));
      dispatch(addNotification({ message: 'Category was successfully reordered', status: 'succeeded' }));
      return updatedDetails;
    } catch (error) {
      console.log(error);
      dispatch(
        addNotification({ message: `Failed to reorder category: ${error?.message || error}`, status: 'failed' }),
      );
      throw error;
    }
  },
);

const initialState = {
  wastageReason: [],
  currentWastageReason: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const wastageReasonSlice = createSlice({
  name: 'wastageReason',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchWastages.pending, setLoading)
      .addCase(fetchWastages.fulfilled, (state, action) => {
        setSucceeded(state);
        state.wastageReason = action.payload;
      })
      .addCase(fetchWastages.rejected, setFailed)

      .addCase(fetchWastage.pending, setLoading)
      .addCase(fetchWastage.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentWastageReason = action.payload;
      })
      .addCase(fetchWastage.rejected, setFailed)

      .addCase(addWastage.pending, setLoading)
      .addCase(addWastage.fulfilled, (state, action) => {
        setSucceeded(state);
        state.wastageReason.push(action.payload);
      })
      .addCase(addWastage.rejected, setFailed)

      .addCase(updateExistingWastage.pending, setLoading)
      .addCase(updateExistingWastage.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.wastageReason.findIndex((reason) => reason.id === updatedDetails.id);

        if (index !== -1) {
          state.wastageReason[index] = updatedDetails;
        }
      })
      .addCase(updateExistingWastage.rejected, setFailed)

      .addCase(removeWastage.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;
        state.wastageReason = state.wastageReason.filter((reason) => reason.id !== id);
      })
      .addCase(removeWastage.pending, setLoading)
      .addCase(removeWastage.rejected, setFailed)
      .addCase(reorderWastage.pending, setLoading)
      .addCase(reorderWastage.fulfilled, setSucceeded)
      .addCase(reorderWastage.rejected, setFailed);
  },
});

export const { setQueryDetails } = wastageReasonSlice.actions;
export default wastageReasonSlice.reducer;
