import React from 'react';

import List from '../../../utilities/list/list';
import Switch from '../../../utilities/switch/switch';

import { IoTrash } from 'react-icons/io5';
import { MdModeEditOutline } from 'react-icons/md';
import { GiHamburgerMenu } from 'react-icons/gi';

import styles from './sales-taxes-list.module.scss';

const SalesTaxesList = React.memo(({ items, onEdit, onDelete, onAssignToItemsClick, onToggleActive, isLoading }) => {
  const renderDragAndDropIcon = (item) => (
    <div className={styles.drag_icon}>
      <GiHamburgerMenu />
    </div>
  );

  const renderInputName = (item) => <div>{item.name}</div>;

  const renderInputRate = (item) => <div>{`${item.rate}%`}</div>;

  const renderSwitcher = (item) => (
    <Switch id={item.id} roleName={'User'} isActive={item.is_active} onSwitch={onToggleActive} />
  );

  const renderLink = (item) => (
    <span className={styles.assign_item_link} onClick={(e) => onAssignToItemsClick(item.id)}>
      Assign Items
    </span>
  );

  const columnConfig = [
    {
      key: 'sort',
      header: 'Sort',
      render: renderDragAndDropIcon,
    },
    {
      key: 'name',
      header: 'Tax Name',
      render: renderInputName,
    },
    {
      key: 'rate',
      header: 'Tax Rate',
      render: renderInputRate,
    },
    {
      key: 'is_active',
      header: 'Status',
      render: renderSwitcher,
    },
    {
      key: 'apply',
      header: 'Apply',
      render: renderLink,
    },
  ];

  const rowActions = [
    {
      icon: <MdModeEditOutline />,
      onClick: onEdit,
      label: 'Edit category',
    },
    {
      icon: <IoTrash />,
      onClick: onDelete,
      label: 'Delete category',
    },
  ];

  const taxesSortedById = items.slice().sort((a, b) => a.id - b.id);

  return (
    <List
      //isDragable={true}
      data={taxesSortedById}
      columnConfig={columnConfig}
      rowActions={rowActions}
      isLoading={isLoading}
      noDataMessage={'No Records found.'}
    />
  );
});

export default SalesTaxesList;
