import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuthState } from './redux/authSlice.js';

import Login from './components/auth/login/authentication';
import ForgotPassword from './components/auth/forgot-password/forgot-password';
import ResetPassword from './components/auth/reset-password/reset-password.jsx';
import NewRegistration from './components/auth/new-registration/new-registration.jsx';
import BusinessInfo from './components/auth/business-info/business-info.jsx';
import Home from './components/layout/home/home.jsx';

import styles from './assets/styles/app.scss';

function App() {
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    dispatch(checkAuthState());
  }, [dispatch]);

  return (
    <>
      <Router>
        <div className={styles.app} style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Routes>
            {!isAuthenticated ? (
              <>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/new-registration" element={<NewRegistration />} />
                <Route path="/business-info" element={<BusinessInfo />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="*" element={<Navigate to="/login" replace />} />
              </>
            ) : (
              <>
                <Route path="/" element={<Navigate to="/dashboard" replace />} />
                <Route path="*" element={<Home />} />
              </>
            )}
          </Routes>
        </div>
      </Router>
    </>
  );
}

export default App;
