import dashboardStore from "./dashboard-store";

export const api = {
  get: (url, config) => {
    const urlParams = new URLSearchParams(url.split("?")[1]);
    const startDate = urlParams.get("start_date");
    const endDate = urlParams.get("end_date");

    return new Promise((resolve, reject) => {
      if (!!url && !!config.headers.TOKEN) {
        setTimeout(
          () =>
            resolve({
              data: { data: dashboardStore.getMetrics(startDate, endDate) },
            }),
          1000
        );
      } else {
        reject(new Error("Token or url is not provided"));
      }
    });
  },
};
