import React, { useState } from "react";

import PageTitle from "../../../../utilities/page-title/page-title";
import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import { BsFillPersonFill } from "react-icons/bs";
import styles from "./clocked-in-employees.module.scss";

const ClockedInEmployeesWidget = ({ data, startDate, endDate, isLoading }) => {
  const { id, name, values } = data;

  return (
    <div className={styles.wrapper}>
      <PageTitle name={name} className={styles.subtitle} />
      <div className={styles.content}>
        {isLoading && <BackdropLoading />}
        {values &&
          values.map((value) => (
            <div key={value.name} className={styles.employee}>
              <div className={styles.left}>
                <div className={styles.icon_wrapper}>
                  <BsFillPersonFill />
                  {/* <img src={item.icon} alt={`${item.name} icon`} className={styles.icon} /> */}
                </div>
                <div className={styles.info_wrapper}>
                  <div className={styles.name}>{value.name}</div>
                  <div className={styles.time}>{value.time}</div>
                </div>
              </div>
              <div className={styles.right}>
                <div className={styles.hours}>{value.hours}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ClockedInEmployeesWidget;
