export const valueConverter = (value, type) => {
  if (value === null || value === undefined) return value;

  switch (type) {
    case 'number':
      const numberValue = parseFloat(value);
      return isNaN(numberValue) ? 0 : numberValue;
    case 'boolean':
      return Boolean(value);
    case 'string':
      return value.toString();
    default:
      return value;
  }
};

export const isObject = (value) => {
  return value != null && (value.constructor === Object || (!value.constructor && typeof value === 'object'));
};

export const isObjectEmpty = (objectName) => {
  return objectName && Object.keys(objectName).length === 0 && objectName.constructor === Object;
};

export const checkIfArrayOfObjects = (value) => value.every((item) => isObject(item));

export const mergeArrayById = (stateArray = [], newArray = [], idKey = 'id') => {
  if (!Array.isArray(stateArray)) stateArray = [];
  if (!Array.isArray(newArray)) newArray = [];

  const updatedArray = stateArray.map((item) => {
    if (!item || typeof item[idKey] === 'undefined') return item;

    const newItem = newArray.find((newItem) => newItem && newItem[idKey] === item[idKey]);
    return newItem ? { ...item, ...newItem } : item;
  });

  const newUniqueItems = newArray.filter(
    (newItem) => newItem && !stateArray.some((item) => item && item[idKey] === newItem[idKey]),
  );

  return [...updatedArray, ...newUniqueItems];
};

export const getMaxValueFromArray = (arr, key) => Math.max(0, ...arr.map((item) => item?.[key]));

export const compareArraysOfObjectsById = (arr1, arr2) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((item) => {
      const compareItem = arr2.find(
        (comparedItem) => isObject(comparedItem) && isObject(item) && comparedItem.id === item.id,
      );
      return compareItem && Object.keys(item).every((key) => item[key] === compareItem[key]);
    })
  );
};

export function sortItems(items, key, order = 'asc') {
  const modifier = order === 'asc' ? 1 : -1;
  return [...items].sort((a, b) => modifier * String(a[key]).localeCompare(String(b[key])));
}
