import { useMemo } from 'react';
import Switch from '../../../../utilities/switch/switch';

import styles from '../customer-receipt-settings.module.scss';

const RECEIPT_OPTIONS = {
  mainOptions: [
    { name: 'show_order_id', title: 'Show Order ID' },
    { name: 'show_modifiers', title: 'Show Modifiers' },
    { name: 'show_order_note', title: 'Show Order Note' },
    { name: 'show_order_type', title: 'Show Order Type' },
    { name: 'show_employee', title: 'Show Employee' },
    { name: 'show_order_time', title: 'Show Order Time' },
    { name: 'show_print_time', title: 'Show Print Time' },
    { name: 'show_split_amount', title: 'Show Split Amount' },
    { name: 'show_refund_amount', title: 'Show Order Refund Amount' },
    { name: 'show_rolled_over', title: 'Show Rolled Over' },
    // { name: 'rolled_over_number', title: 'Rolled Over Number' },
  ],
  venueInformation: [
    { name: 'show_venue_logo', title: 'Logo' },
    { name: 'show_venue_address', title: 'Address' },
    { name: 'show_venue_phone', title: 'Phone' },
    { name: 'show_website_address', title: 'Website' },
  ],
  customerInformation: [
    { name: 'show_customer_name', title: 'Name' },
    { name: 'show_customer_phone', title: 'Mobile' },
    { name: 'show_customer_address', title: 'Address' },
  ],
  additionalOptions: [
    { name: 'show_tip_suggestion', title: 'Show Tip Suggestion' },
    { name: 'show_tip_line_for_cash', title: 'Show Tip Line for Cash' },
    { name: 'show_qr_code', title: 'Show QR Code' },
    { name: 'show_non_cash_adjustments_surcharge', title: 'Show Non-Cash Adjustments/Surcharge' },
  ],
};

const OptionRow = ({ option, receiptOptions, onOptionChange }) => {
  return (
    <div className={styles.row}>
      <span>{option.title}</span>
      <Switch id={option.name} isActive={receiptOptions[option?.name]} onSwitch={onOptionChange} />
    </div>
  );
};

const CustomerReceiptSettingsForm = ({ settings, onFormChange }) => {
  const activeOptions = useMemo(() => {
    return Object.entries(RECEIPT_OPTIONS).reduce((acc, [key, value]) => {
      const filteredItems = value.filter((obj) => obj.name in settings);
      acc[key] = filteredItems;
      return acc;
    }, {});
  }, [settings]);

  return (
    <div className={styles.list_controller}>
      <div className={styles.settings}>
        {activeOptions.mainOptions.map((setting) => (
          <OptionRow key={setting.name} receiptOptions={settings} option={setting} onOptionChange={onFormChange} />
        ))}
      </div>
      <h3 className={styles.customer_title}>Show Venue Information</h3>
      <div className={styles.settings}>
        {activeOptions.venueInformation.map((setting) => (
          <OptionRow key={setting.name} receiptOptions={settings} option={setting} onOptionChange={onFormChange} />
        ))}
      </div>
      <h3 className={styles.customer_title}>Show Customer Information</h3>
      <div className={styles.settings}>
        {activeOptions.customerInformation.map((setting) => (
          <OptionRow key={setting.name} receiptOptions={settings} option={setting} onOptionChange={onFormChange} />
        ))}
      </div>
      <div className={styles.customer_title}></div>
      <div className={styles.settings}>
        {activeOptions.additionalOptions.map((setting) => (
          <OptionRow key={setting.name} receiptOptions={settings} option={setting} onOptionChange={onFormChange} />
        ))}
      </div>
    </div>
  );
};

export default CustomerReceiptSettingsForm;
