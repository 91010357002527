import { z } from "zod";

export const registrationSchema = z.object({
  name: z
    .string()
    .min(1, { message: "Please enter Restaurant Name" })
    .max(30, { message: "Restaurant Name can contain a maximum of 30 characters" }),
  
  phone: z
    .string()
    .min(7, { message: "Phone must contain at least 7 characters" })
    .max(16, { message: "Phone can contain a maximum of 16 characters" })
    .regex(/^\d+$/, { message: "Phone number must contain only digits" }),
  
  email: z
    .string()
    .min(3, { message: "Email must contain at least 3 characters" })
    .email({ message: "Please enter a valid Email Address" }),
  
  website: z
    .string()
    .min(3, { message: "Email must contain at least 3 characters" })
    .url({ message: "Please enter a valid URL, starting from https://www." }),
});
