import React from "react";
import Chartkick, { PieChart } from "react-chartkick";
import { Chart } from "chart.js";

import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  PieController, // Import PieController
} from "chart.js";

import styles from "./payment-methods.module.scss";

ChartJS.register(ArcElement, Tooltip, Legend, PieController); // Register PieController
Chartkick.addAdapter(ChartJS);

const PaymentMethodsDonutChart = ({ data, isLoading }) => {
  return (
    <div className={styles.chart_wrapper}>
      {isLoading && <BackdropLoading />}
      <PieChart
        data={data}
        id="payment-method-chart"
        donut={true}
        messages={{ empty: "No Records available." }}
        prefix="$ "
        thousands=","
        round={2}
        colors={[
          "#ED5950",
          "#70c043",
          "#eac30b",
          "#a25d9d",
          "#cd3950",
          "#42d7ae",
        ]}
        library={{
          cutout: "50%", // Creates the donut effect
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                usePointStyle: true,
                boxWidth: 7,
                padding: 20,
              },
            },
            tooltip: {
              callbacks: {
                label: (context) => `${context.label}: $${context.raw}`,
              },
            },
          },
        }}
      />
    </div>
  );
};

export default PaymentMethodsDonutChart;
