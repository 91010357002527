import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { showModal, hideModal } from '../../../../../redux/modalSlice';

import {
  removeWastage,
  updateExistingWastage,
  addWastage,
  reorderWastage,
  setQueryDetails,
} from '../../../../../redux/wastageReasonsSlice';

import { sortItems } from '../../../../utilities/utils';

import { useAppContext } from '../../../../../context/context';
import { valueConverter } from '../../../../utilities/utils';

const useWastageReasonsManagement = (wastageReasons) => {
  const [wastageState, setWastageState] = useState(wastageReasons);
  const [initialWastageState, setInitialWastageState] = useState(wastageReasons);
  const dispatch = useDispatch();
  const { token } = useAppContext();

  useEffect(() => {
    setWastageState(wastageReasons);
    setInitialWastageState(wastageReasons);
  }, [wastageReasons]);

  const handleSetSearch = (result = '') => {
    dispatch(setQueryDetails({ search: result }));
  };

  const handleAddReason = useCallback(
    (newWastageName) => {
      dispatch(addWastage({ newWastageDetails: { name: newWastageName }, token }));
    },
    [dispatch, token],
  );

  const handleToggleWastageActive = useCallback((id, value) => {
    setWastageState((prev) => {
      return prev.map((wastage) => {
        if (wastage.id === parseFloat(id)) {
          return { ...wastage, is_active: value };
        }

        return wastage;
      });
    });
  }, []);

  const handleconfirmAndDeleteReason = useCallback(
    (id) => {
      dispatch(
        showModal({
          modalId: 'modal-delete-wastage',
          data: {
            type: 'confirmation',
            title: 'Delete Confirmation',
            message: 'Are you sure you want to delete this wastage',
            actions: [
              {
                title: 'Delete',
                onAction: () => {
                  dispatch(removeWastage({ id, token }));
                  dispatch(hideModal('modal-delete-wastage'));
                },
              },
              { title: 'Cancel', button_variant: 'grey', onAction: () => dispatch(hideModal('modal-delete-wastage')) },
            ],
          },
        }),
      );
    },
    [dispatch, token],
  );

  const handleSaveChanges = useCallback(() => {
    const updatedWastages = wastageState.filter(
      (item, index) => JSON.stringify(initialWastageState[index]) !== JSON.stringify(wastageState[index]),
    );

    Promise.allSettled(
      updatedWastages.map((item) => dispatch(updateExistingWastage({ id: item.id, updatedDetails: item, token }))),
    );
  }, [dispatch, initialWastageState, token, wastageState]);

  const handleUpdateReasonName = useCallback((e, type) => {
    const { id, value } = e.target;
    setWastageState((prev) => {
      return prev.map((wastage) => {
        if (wastage.id === parseFloat(id)) {
          return { ...wastage, name: valueConverter(value, type) };
        }

        return wastage;
      });
    });
  }, []);

  const handleChangeList = useCallback((state) => {
    setWastageState(state);
  }, []);

  const handleDragWastage = (item) => {
    const { sourceId, index } = item;

    const old_position = wastageState.find((reason) => reason.id === sourceId)?.sort;
    const new_position = sortItems(wastageState, 'sort')[index]?.sort;

    if (old_position !== new_position) {
      dispatch(reorderWastage({ id: sourceId, updatedDetails: { old_position, new_position }, token }));
    }
  };

  return {
    wastageState,
    initialWastageState,
    handleAddReason,
    handleToggleWastageActive,
    handleconfirmAndDeleteReason,
    handleUpdateReasonName,
    handleChangeList,
    handleSetSearch,
    handleSaveChanges,
    handleDragWastage,
  };
};

export default useWastageReasonsManagement;
