import React, { useState } from "react";
import Chartkick from "react-chartkick";
import { LineChart } from "react-chartkick";
import { Chart } from "chart.js";

import PageTitle from "../../../../utilities/page-title/page-title";
import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController,
} from "chart.js";

import styles from "./guests.module.scss";

ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
  LineController
);
Chartkick.addAdapter(ChartJS);

const GuestsLineChart = ({ data, isLoading }) => {
  const { id, name, count, total, values } = data;

  return (
    <div className={styles.graph_wrapper}>
      <div className={styles.chart_wrapper}>
        <PageTitle name={name} className={styles.subtitle} />
        {isLoading && <BackdropLoading />}
        <LineChart
          data={values}
          id={id}
          prefix="$"
          thousands=","
          round={2}
          colors={["#ED5950"]}
          messages={{ empty: "No Records available." }}
        />
      </div>
      <div className={styles.totals}>
        <div className={styles.count_wrapper}>
          <div className={styles.label}>Count</div>
          <div className={styles.value}>{count}</div>
        </div>
        <div className={styles.value_wrapper}>
          <div className={styles.label}>Value</div>
          <div className={styles.value}>${total}</div>
        </div>
      </div>
    </div>
  );
};

export default GuestsLineChart;
