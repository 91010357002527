import React, { useState } from "react";

import PageTitle from "../../../../utilities/page-title/page-title";
import BackdropLoading from "../../../../utilities/backdrop-loading/backdrop-loading";

import { BsFillPersonFill } from "react-icons/bs";
import styles from "./top-selling-items.module.scss";

const TopSellingItemsWidget = ({ data, startDate, endDate, isLoading }) => {
  const { id, name, values } = data;

  return (
    <div className={styles.wrapper}>
      <PageTitle name={name} className={styles.subtitle} />
      <div className={styles.content}>
        {isLoading && <BackdropLoading />}
        {values &&
          values.map((value) => (
            <div key={value.name} className={styles.item}>
              <div className={styles.name}>{value.name}</div>
              <div className={styles.price}>${value.price}</div>
              <div className={styles.quantity}>{value.quantity}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TopSellingItemsWidget;
