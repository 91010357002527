// import { api } from './base';
import { api } from "./mocked/dashboard/mockedAxiosApi";

const handleApiResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    throw error.response?.data || "API request failed";
  }
};

export const getMetricsData = ({ start_date, end_date, token }) => {
  return handleApiResponse(() =>
    api.get(`dashboard/metrics?start_date=${start_date}&end_date=${end_date}`, {
      headers: {
        TOKEN: token,
      },
    })
  );
};
