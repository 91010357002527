import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { z } from 'zod';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CryptoJS from 'crypto-js';

import { showModal, hideModal } from '../../../redux/modalSlice';

import { loginUser } from '../../../redux/authSlice';

import Modal from '../../utilities/modal/modal-template';
import Checkbox from '../../utilities/checkbox/checkbox';
import Input from '../../utilities/input/input';
import Button from '../../utilities/button/button';
import BackdropLoading from '../../utilities/backdrop-loading/backdrop-loading';

import { FaEyeSlash } from 'react-icons/fa6';
import { FaEye } from 'react-icons/fa';

import styles from './authentication.module.scss';
import Logo from '../../../assets/img/crunch-logo-512px.png';

const registrationSchema = z.object({
  email: z.string().email('Invalid email format'),
  password: z.string().min(5, 'Password must be at least 5 characters long'),
});

const checkValueForEmpty = (value) => value === '' || value === undefined || value === null;

function Authentication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginStatus = useSelector((state) => state.auth.status);

  const [showPassword, setShowPassword] = useState(false);

  const [formState, setFormState] = useState({
    email: '',
    password: '',
    rememberMe: false,
  });
  const [errors, setErrors] = useState({});

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    setFormState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = registrationSchema.safeParse(formState);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      setErrors({});

      const hashedPassword = CryptoJS.SHA256(formState.password).toString(CryptoJS.enc.Base64);

      try {
        await dispatch(loginUser({ userEmail: formState.email, password: hashedPassword })).unwrap();
        navigate('/dashboard');
      } catch {
        showModalIncorrectCredentials();
      }
    }
  };

  const showModalIncorrectCredentials = useCallback(() => {
    dispatch(
      showModal({
        modalId: 'modal-incorrect-credentials',
        data: {
          type: 'confirmation',
          title: 'Invalid Login Credentials',
          actions: [
            {
              title: 'Ok',
              onAction: () => {
                dispatch(hideModal('modal-incorrect-credentials'));
              },
            },
          ],
          className: styles.modal,
        },
      }),
    );
  }, [dispatch]);

  const handleCheckbox = (_, isChecked) => {
    handleFormChange({ target: { name: 'rememberMe', value: isChecked } });
  };

  return (
    <>
      {loginStatus === 'loading' && <BackdropLoading />}
      <div className={styles.wrapper}>
        <div className={styles.login}>
          <img className={styles.logo} src={Logo} draggable={false} alt="Snack POC logo" />
          <form className={styles.form} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <Input
                value={formState.email}
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                onChange={handleFormChange}
                activePlaceholder={true}
                placeholder={'Email Address'}
                errors={errors.email?._errors}
              />
            </div>

            <div className={styles.formGroup}>
              <Input
                value={formState.password}
                id="password"
                name="password"
                autoComplete="current-password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleFormChange}
                activePlaceholder={true}
                suffixIcon={!checkValueForEmpty(formState.password) ? showPassword ? <FaEye /> : <FaEyeSlash /> : null}
                suffixButtonCallback={() => setShowPassword((prev) => !prev)}
                placeholder={'Password'}
                errors={errors.password?._errors}
              />
            </div>
            <div className={styles.remember_me}>
              <Checkbox isChecked={formState.rememberMe} handleOnChange={handleCheckbox} />
              <p>Remember me</p>
            </div>
            <Button
              title="Sign In"
              type="submit"
              className={styles.submit_button}
              style={{ width: '100%', fontSize: '1rem' }}
            />
          </form>
          <div className={styles.forgot_password}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <div className={styles.sign_up}>
            <p>New to Crunch POS?</p>
            <Link to="/new-registration">Sign Up</Link>
          </div>
        </div>
        <Modal />
      </div>
    </>
  );
}

export default Authentication;
