import React, { useState } from 'react';
import clsx from 'clsx';
import { nanoid } from 'nanoid';

import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';

import styles from './selector.module.scss';

const checkValueForEmpty = (value) => value === '' || value === undefined || value === null;

const Selector = ({
  placeholder,
  icon: Icon,
  items,
  label,
  required = false,
  name = nanoid(),
  activePlaceholder = false,
  selectedItem,
  onSelectItem,
  initialPlaceholderVisibility = true,
  showArrows = true,
  errors = [],
  className,
  style,
}) => {
  const [placeholderVisible, setPlaceholderVisible] = useState(initialPlaceholderVisibility);

  const selectionHandler = (newValue) => {
    setPlaceholderVisible(false);
    onSelectItem(newValue);
  };

  return (
    <div className={styles.wrapper}>
      <div className={clsx(styles.selector, className)} style={style}>
        {label && (
          <label className={clsx(styles.label, required ? styles.asterisk : '')} htmlFor={name}>
            {label}
          </label>
        )}
        <div className={styles.select_container}>
          {activePlaceholder && placeholder && (
            <p
              className={clsx(
                checkValueForEmpty(selectedItem) ? styles.inner_placeholder : styles.inner_placeholder_active,
                required && styles.asterisk,
              )}
            >
              {placeholder}
            </p>
          )}
          <select
            //value={placeholderVisible ? (activePlaceholder ? undefined : placeholder) : selectedItem}
            value={selectedItem ? selectedItem : placeholder}
            name={name}
            onChange={(e) => selectionHandler(e.target.value)}
            className={styles.select}
            placeholder={activePlaceholder ? undefined : placeholder}
          >
            {placeholderVisible && <option value="">{activePlaceholder ? undefined : placeholder}</option>}

            {items?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
          {Icon ? <Icon className={styles.icon} /> : <IoIosArrowDown className={styles.icon} />}
        </div>
        {!!errors.length && (
          <div className={styles.error_container}>
            {errors.map((error, index) => (
              <div key={index} className={styles.error}>
                {error}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Selector;
