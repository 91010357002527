import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Dashboard from '../../main/dashboard/dashboard';

import Employees from '../../main/employee/employees/employees';
import EditEmployee from '../../main/employee/employees/edit-employee';
import AddEmployee from '../../main/employee/employees/add-employee';
import TeamRoles from '../../main/employee/team-roles/team-roles';
import Timesheet from '../../main/employee/timesheet/timesheet';
import TimesheetDetails from '../../main/employee/timesheet/timesheet-details';
import EditTimesheetDetails from '../../main/employee/timesheet/timesheet-details-edit';
import InactiveEmployees from '../../main/employee/inactive-employees/inactive-employees';
import EditInactiveEmployees from '../../main/employee/inactive-employees/edit-inactive-employee';

import Customers from '../../main/customers/customers';
import EditCustomer from '../../main/customers/edit-customer';
import AddCustomer from '../../main/customers/add-customer';

import ReportingOrders from '../../main/reporting/orders/orders';
import ReportingDineInOrders from '../../main/reporting/dine-in-orders/dine-in-orders';
import ReportingOrderItems from '../../main/reporting/order-items/order-items';
import ReportingSalesOverview from '../../main/reporting/sales-overiview/sales-overview';
import ReportingSalesReport from '../../main/reporting/sales-report/sales-report';
import ReportingSalesByCategory from '../../main/reporting/sales-by-category/sales-by-category';
import ReportingTaxes from '../../main/reporting/taxes/taxes';
import ReportingTips from '../../main/reporting/tips/tips';
import ReportingTransactions from '../../main/reporting/transactions/transactions';
import ReportingDeletedTransactions from '../../main/reporting/deleted-transactions/deleted-transactions';
import ReportingDiscounts from '../../main/reporting/discounts/discounts';
import ReportingTopItems from '../../main/reporting/top-items/top-items';
import ReportingCanceledOrders from '../../main/reporting/canceled-orders/canceled-orders';
import ReportingRefunds from '../../main/reporting/refunds/refunds';
import ReportingEmployeeShift from '../../main/reporting/employee-shift-report/employee-shift-report';
import ReportingByModifiers from '../../main/reporting/report-by-modifiers/report-by-modifiers';
import ReportingKitchen from '../../main/reporting/kitchen-report/kitchen-report';
import ReportingNonCashAdjustments from '../../main/reporting/non-cash-adjustments/non-cash-adjustments';
import ReportingLaborSummary from '../../main/reporting/labor-summary/labor-summary';
import ReportingGiftCardPurchase from '../../main/reporting/gift-card-purchase/gift-card-purchase';
import ReportingGiftCardCustomerUsage from '../../main/reporting/gift-card-customer-usage/gift-card-customer-usage';
import ReportingLoyalty from '../../main/reporting/loyalty-report/loyalty-report';
import ReportingBatchClose from '../../main/reporting/batch-close-report/batch-close-report';

import Categories from '../../main/menu/categories/categories';
import HiddenCategories from '../../main/menu/hidden-categories/hidden-categories';
import HiddenItems from '../../main/menu/hidden-items/hidden-items';
import HiddenItemsWebsite from '../../main/menu/hidden-items-website/hidden-items-website';
import Items from '../../main/menu/items/items';
import AddItem from '../../main/menu/items/add-item/add-item';
import EditItem from '../../main/menu/items/edit-item/edit-item';
import Modifiers from '../../main/menu/modifiers/modifiers';
import AddModifierSet from '../../main/menu/modifiers/add-modifier-set';
import EditModifierSet from '../../main/menu/modifiers/edit-modifier-set';
import Options from '../../main/menu/options/options';
import AddOptionSet from '../../main/menu/options/add-option-set';
import EditOptionSet from '../../main/menu/options/edit-option-set';
import Settings from '../../main/menu/settings/settings';

import Information from '../../main/business/information/information';
import BusinessHours from '../../main/business/hours/hours';
import BusinessReporting from '../../main/business/reporting/reporting';
import Tips from '../../main/business/tips/tips';
import ServiceCharges from '../../main/business/service-charges/service-charges';
import SalesTaxes from '../../main/business/sales-taxes/sales-taxes';
import Discounts from '../../main/business/discounts/discounts';
import Printers from '../../main/business/printers/printers';
import AddPrinter from '../../main/business/printers/add-printer';
import EditPrinter from '../../main/business/printers/edit-printer';
import PrinterQueues from '../../main/business/printer-queues/printer-queues';
import BatchCloseTime from '../../main/business/batch-close-time/batch-close-time';
import CancelOrderReasons from '../../main/business/cancel-order-reasons/cancel-order-reasons';
import WastageReasons from '../../main/business/wastage-reasons/wastage-reasons';
import OrderNotes from '../../main/business/order-notes/order-notes';
import OrderTypes from '../../main/business/order-types/order-types';
import AddOrderType from '../../main/business/order-types/add-order-type';
import EditOrderType from '../../main/business/order-types/edit-order-type';
import LoyaltyPrograms from '../../main/business/loyalty-programs/loyalty-programs';
import TerminalSettings from '../../main/business/terminal-settings/terminal-settings';
import AddLoyaltyProgram from '../../main/business/loyalty-programs/add-loyalty-program';
import EditLoyaltyProgram from '../../main/business/loyalty-programs/edit-loyalty-program';
import CustomerReceiptSettings from '../../main/business/customer-receipt-settings/customer-receipt-settings';
import KitchenReceiptSettings from '../../main/business/kitchen-receipt-settings/kitchen-receipt-settings';
import EndOfDayReportSettings from '../../main/business/end-of-day-report-settings/end-of-day-report-settings';
import ShiftReceiptSettings from '../../main/business/shift-receipt-settings/shift-receipt-settings';
import CustomerDisplaySettings from '../../main/business/customer-display-settings/customer-display-settings';
import OtherSettings from '../../main/business/other-settings/other-settings';

import KitchenDisplay from '../../main/kitchen-display/kitchen-display';

import WebsiteDashboard from '../../main/online-website/dashboard/dashboard';
import WebsiteOnlineOrderingHours from '../../main/online-website/online-ordering-hours/online-ordering-hours';
import WebsiteThirdPartyOnlineOrdering from '../../main/online-website/3rd-party-online-ordering/3rd-party-online-ordering';
import WebsiteBannerDetails from '../../main/online-website/banner-details/banner-details';
import WebsitePageContent from '../../main/online-website/page-content/page-content';
import WebsiteAboutBusiness from '../../main/online-website/about-business/about-business';
import WebsiteGallery from '../../main/online-website/gallery/gallery';
import WebsiteTerminalReceiveOrders from '../../main/online-website/terminal-receive-orders/terminal-receive-orders';
import WebsiteSectionSequence from '../../main/online-website/section-sequence/section-sequence';
import WebsiteReservations from '../../main/online-website/reservations/reservations';
import WebsiteEvents from '../../main/online-website/events/events';
import WebsiteCustomerMessages from '../../main/online-website/customer-messages/customer-messages';
import WebsiteCoupon from '../../main/online-website/coupon/coupon';
import WebsitePages from '../../main/online-website/pages/pages';
import WebsiteOtherSettings from '../../main/online-website/other-settings/other-settings';

import FloorPlan from '../../main/floor-plan/floor-plan/floor-plan';
import ClearTables from '../../main/floor-plan/clear-tables/clear-tables';
import FloorPlanSettings from '../../main/floor-plan/settings/settings';

import HappyHour from '../../main/happy-hour/happy-hour';
import Invoices from '../../main/invoices/invoices';
import GiftCard from '../../main/gift-card/gift-card';
import Events from '../../main/events/events';

import Terms from '../../main/terms/terms';
import PrivacyPolicy from '../../main/privacy-policy/privacy-policy';

import clsx from 'clsx';
import styles from './content.module.scss';

const MainContent = ({ isSidebarOpen, isMobile }) => {
  return (
    <div
      className={clsx(styles.main_content, {
        [styles.expanded]: isMobile && !isSidebarOpen,
        [styles.collapsed]: isMobile && isSidebarOpen,
      })}
    >
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/employee/employees" element={<Employees />} />
        <Route path="/employee/employees/:id/edit" element={<EditEmployee />} />
        <Route path="/employee/employees/new" element={<AddEmployee />} />
        <Route path="/employee/roles" element={<TeamRoles />} />
        <Route path="/employee/timesheet" element={<Timesheet />} />
        <Route path="/employee/:id/timesheet_details" element={<TimesheetDetails />} />
        <Route path="/employee/:id/edit_timesheet_details" element={<EditTimesheetDetails />} />
        <Route path="/employee/inactive_employees" element={<InactiveEmployees />} />
        <Route path="/employee/inactive_employees/:id/edit" element={<EditInactiveEmployees />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/customers/:id/edit" element={<EditCustomer />} />
        <Route path="/customers/new" element={<AddCustomer />} />

        <Route path="/reporting/orders" element={<ReportingOrders />} />
        <Route path="/reporting/dine-in-orders" element={<ReportingDineInOrders />} />
        <Route path="/reporting/order-items" element={<ReportingOrderItems />} />
        <Route path="/reporting/sales-overview" element={<ReportingSalesOverview />} />
        <Route path="/reporting/sales-report" element={<ReportingSalesReport />} />
        <Route path="/reporting/sales-by-category" element={<ReportingSalesByCategory />} />
        <Route path="/reporting/taxes" element={<ReportingTaxes />} />
        <Route path="/reporting/tips" element={<ReportingTips />} />
        <Route path="/reporting/transactions" element={<ReportingTransactions />} />
        <Route path="/reporting/deleted-transactions" element={<ReportingDeletedTransactions />} />
        <Route path="/reporting/discounts" element={<ReportingDiscounts />} />
        <Route path="/reporting/top-items" element={<ReportingTopItems />} />
        <Route path="/reporting/canceled-orders" element={<ReportingCanceledOrders />} />
        <Route path="/reporting/refunds" element={<ReportingRefunds />} />
        <Route path="/reporting/employee-shift-report" element={<ReportingEmployeeShift />} />
        <Route path="/reporting/report-by-modifiers" element={<ReportingByModifiers />} />
        <Route path="/reporting/kitchen-report" element={<ReportingKitchen />} />
        <Route path="/reporting/non-cash-adjustments" element={<ReportingNonCashAdjustments />} />
        <Route path="/reporting/labor-summary" element={<ReportingLaborSummary />} />
        <Route path="/reporting/gift-card-purchase" element={<ReportingGiftCardPurchase />} />
        <Route path="/reporting/gift-card-customer-usage" element={<ReportingGiftCardCustomerUsage />} />
        <Route path="/reporting/loyalty-report" element={<ReportingLoyalty />} />
        <Route path="/reporting/batch-close-report" element={<ReportingBatchClose />} />

        <Route path="/menu/items" element={<Items />} />
        <Route path="/menu/items/new" element={<AddItem />} />
        <Route path="/menu/items/:id/edit" element={<EditItem />} />
        <Route path="/menu/categories" element={<Categories />} />
        <Route path="/menu/modifiers" element={<Modifiers />} />
        <Route path="/menu/modifiers/new" element={<AddModifierSet />} />
        <Route path="/menu/modifiers/:id/edit" element={<EditModifierSet />} />
        <Route path="/menu/options" element={<Options />} />
        <Route path="/menu/options/new" element={<AddOptionSet />} />
        <Route path="/menu/options/:id/edit" element={<EditOptionSet />} />
        <Route path="/menu/hidden_items" element={<HiddenItems />} />
        <Route path="/menu/hidden_items_website" element={<HiddenItemsWebsite />} />
        <Route path="/menu/hidden_categories" element={<HiddenCategories />} />
        <Route path="/menu/settings" element={<Settings />} />

        <Route path="/business/information" element={<Information />} />
        <Route path="/business/hours" element={<BusinessHours />} />
        <Route path="/business/reporting" element={<BusinessReporting />} />
        <Route path="/business/tips" element={<Tips />} />
        <Route path="/business/service_charges" element={<ServiceCharges />} />
        <Route path="/business/sales_taxes" element={<SalesTaxes />} />
        <Route path="/business/discounts" element={<Discounts />} />
        <Route path="/business/printers" element={<Printers />} />
        <Route path="/business/printers/new" element={<AddPrinter />} />
        <Route path="/business/printers/:id/edit" element={<EditPrinter />} />
        <Route path="/business/printer_queues" element={<PrinterQueues />} />
        <Route path="/business/batch_close_time" element={<BatchCloseTime />} />
        <Route path="/business/cancel_order_reasons" element={<CancelOrderReasons />} />
        <Route path="/business/wastage_reasons" element={<WastageReasons />} />
        <Route path="/business/order_notes" element={<OrderNotes />} />
        <Route path="/business/order_types" element={<OrderTypes />} />
        <Route path="/business/order_types/new" element={<AddOrderType />} />
        <Route path="/business/order_types/:id/edit" element={<EditOrderType />} />
        <Route path="/business/loyalty_programs" element={<LoyaltyPrograms />} />
        <Route path="/business/terminal_settings" element={<TerminalSettings />} />
        <Route path="/business/loyalty_programs/:id/edit" element={<EditLoyaltyProgram />} />
        <Route path="/business/loyalty_programs/new" element={<AddLoyaltyProgram />} />
        <Route path="/business/customer_receipt_settings" element={<CustomerReceiptSettings />} />
        <Route path="/business/kitchen_receipt_settings" element={<KitchenReceiptSettings />} />
        <Route path="/business/end_of_day_report_settings" element={<EndOfDayReportSettings />} />
        <Route path="/business/shift_receipt_settings" element={<ShiftReceiptSettings />} />
        <Route path="/business/customer_display_settings" element={<CustomerDisplaySettings />} />
        <Route path="/business/other_settings" element={<OtherSettings />} />

        <Route path="/kitchen-display" element={<KitchenDisplay />} />

        <Route path="/online-website/dashboard" element={<WebsiteDashboard />} />
        <Route path="/online-website/online-ordering-hours" element={<WebsiteOnlineOrderingHours />} />
        <Route path="/online-website/3rd-party-online-ordering" element={<WebsiteThirdPartyOnlineOrdering />} />
        <Route path="/online-website/banner-details" element={<WebsiteBannerDetails />} />
        <Route path="/online-website/page-content" element={<WebsitePageContent />} />
        <Route path="/online-website/about-business" element={<WebsiteAboutBusiness />} />
        <Route path="/online-website/gallery" element={<WebsiteGallery />} />
        <Route path="/online-website/terminal-receive-orders" element={<WebsiteTerminalReceiveOrders />} />
        <Route path="/online-website/section-sequence" element={<WebsiteSectionSequence />} />
        <Route path="/online-website/reservations" element={<WebsiteReservations />} />
        <Route path="/online-website/events" element={<WebsiteEvents />} />
        <Route path="/online-website/customer-messages" element={<WebsiteCustomerMessages />} />
        <Route path="/online-website/coupon" element={<WebsiteCoupon />} />
        <Route path="/online-website/pages" element={<WebsitePages />} />
        <Route path="/online-website/other-settings" element={<WebsiteOtherSettings />} />

        <Route path="/floor-plan/floor-plan" element={<FloorPlan />} />
        <Route path="/floor-plan/clear-tables" element={<ClearTables />} />
        <Route path="/floor-plan/settings" element={<FloorPlanSettings />} />

        <Route path="/happy-hour" element={<HappyHour />} />
        <Route path="/invoices" element={<Invoices />} />
        <Route path="/gift-card" element={<GiftCard />} />
        <Route path="/events" element={<Events />} />

        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </div>
  );
};

export default MainContent;
