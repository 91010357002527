import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "chart.js";

import { useAppContext } from "../../../context/context";
import { fetchMetricsData } from "../../../redux/dashboardSlice";

import Button from "../../utilities/button/button";
import PageTitle from "../../utilities/page-title/page-title";
import Metric from "./statistics/metric";
import NetSalesBarChart from "./graphs/net-sales/net-sales";
import GrossSalesBarChart from "./graphs/gross-sales/gross-sales";
import HourlyRevenueLineChart from "./graphs/hourly-new-revenue/hourly-new-revenue";
import PaymentMethodsChart from "./graphs/payment-methods/payment-methods";
import DiscountLineChart from "./graphs/discounts/discounts";
import GuestsLineChart from "./graphs/guests/guests";
import ClockedInEmployeesWidget from "./widgets/clocked-in-employees/clocked-in-employees";
import TopSellingItemsWidget from "./widgets/top-selling-items/top-selling-items";

import qrCode from "../../../assets/img/qr_code.png";

import clsx from "clsx";
import styles from "./dashboard.module.scss";

const adjustForTimeZone = (date) => {
  const offsetInMinutes = date.getTimezoneOffset();
  return new Date(date.getTime() - offsetInMinutes * 60000);
};

function Dashboard() {
  // Hooks
  const dispatch = useDispatch();
  const { token } = useAppContext();

  // Store
  const dashboard_data = useSelector((state) => state.dashboard.dashboard_data);
  const status = useSelector((state) => state.dashboard.status);

  // Local state
  const today = new Date(); // Date
  const [fromDate, setFromDate] = useState(today); // Date
  const [fromDateISO, setFromDateISO] = useState(null); // Date
  const [toDate, setToDate] = useState(today); // Date
  const [toDateISO, setToDateISO] = useState(null); // Date
  const [metrics, setMetrics] = useState([]); // Metrics
  const [netSalesGraphData, setNetSalesGraphData] = useState({}); // Graphs
  const [grossSalesGraphData, setGrossSalesGraphData] = useState({}); // Graphs
  const [hourlyNewRevenueGraphData, setHourlyNewRevenueGraphData] = useState(
    {}
  ); // Graphs
  const [paymentMethodsGraphData, setPaymentMethodsGraphData] = useState({}); // Graphs
  const [discountsGraphData, setDiscountsGraphData] = useState({}); // Graphs
  const [guestsGraphData, setGuestsGraphData] = useState({}); // Graphs
  const [clockedInEmployeesData, setClockedInEmployeesData] = useState({}); // Info block
  const [topSellingItemsData, setTopSellingItemsData] = useState({}); // Info block

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchMetricsData({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (
      dashboard_data &&
      dashboard_data.metrics &&
      dashboard_data.graphs &&
      dashboard_data.metrics.length > 0 &&
      dashboard_data.graphs.length > 0
    ) {
      setMetrics(dashboard_data.metrics);
      setNetSalesGraphData(dashboard_data.graphs[0]);
      setGrossSalesGraphData(dashboard_data.graphs[1]);
      setHourlyNewRevenueGraphData(dashboard_data.graphs[2].values);
      setPaymentMethodsGraphData(dashboard_data.graphs[3].values);
      setDiscountsGraphData(dashboard_data.graphs[4]);
      setGuestsGraphData(dashboard_data.graphs[5]);
      setClockedInEmployeesData(dashboard_data.graphs[6]);
      setTopSellingItemsData(dashboard_data.graphs[7]);
    }
  }, [dashboard_data]);

  useEffect(() => {
    if (!fromDate || !toDate) return;

    const adjustedClockIn = adjustForTimeZone(fromDate);
    const adjustedClockOut = adjustForTimeZone(toDate);

    setFromDateISO(adjustedClockIn.toISOString());
    setToDateISO(adjustedClockOut.toISOString());
  }, [fromDate, toDate]);

  // Handlers
  const handleSelectFromDate = (date) => {
    setFromDate(new Date(date));
  };

  const handleSelectToDate = (date) => {
    setToDate(new Date(date));
  };

  const handleAddNewItem = () => {
    dispatch(
      fetchMetricsData({ start_date: fromDateISO, end_date: toDateISO, token })
    );
  };

  //console.log("status", status);

  return (
    <div className={styles.dashboard}>
      <PageTitle name={"Dashboard"} />
      <div className={styles.filters}>
        <DatePicker
          selected={fromDate}
          onChange={(date) => handleSelectFromDate(date)}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <DatePicker
          selected={toDate}
          onChange={(date) => handleSelectToDate(date)}
          showTimeSelect
          dateFormat="yyyy-MM-dd HH:mm"
          timeFormat="HH:mm"
          timeIntervals={15}
          className={styles.datepicker}
          placeholderText="Select a date and time"
        />
        <Button
          className={styles.search_button}
          title={"Search"}
          onClick={handleAddNewItem}
        />
      </div>

      <div className={styles.metrics}>
        <div className={styles.row}>
          <div className={styles.graph_item}>
            {netSalesGraphData.values && (
              <NetSalesBarChart
                data={netSalesGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === "loading"}
              />
            )}
          </div>
          <div className={styles.graph_item}>
            {grossSalesGraphData.values && (
              <GrossSalesBarChart
                data={grossSalesGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === "loading"}
              />
            )}
          </div>
          <div className={styles.statistics}>
            <PageTitle
              name={"Today`s Statistics"}
              className={styles.subtitle}
            />
            <div className={styles.metrics}>
              {metrics &&
                metrics.map((metric) => (
                  <Metric item={metric} key={metric.id} />
                ))}
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.graph_item_full}>
            <PageTitle
              name={"Hourly New Revenue"}
              className={styles.subtitle}
            />
            <div
              className={styles.graph_content}
              style={{ marginBottom: "20px" }}
            >
              <HourlyRevenueLineChart
                data={hourlyNewRevenueGraphData}
                isLoading={status === "loading"}
              />
            </div>
          </div>
        </div>

        <div className={styles.row}>
          <div className={styles.graph_item}>
            <PageTitle name={"Payment Methods"} className={styles.subtitle} />
            <div className={styles.graph_content}>
              <PaymentMethodsChart
                data={paymentMethodsGraphData}
                isLoading={status === "loading"}
              />
            </div>
          </div>
          <div className={styles.graph_item}>
            {discountsGraphData.values && (
              <DiscountLineChart
                data={discountsGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === "loading"}
              />
            )}
          </div>
          <div className={styles.statistics}>
            {guestsGraphData.values && (
              <GuestsLineChart
                data={guestsGraphData}
                startDate={fromDateISO}
                endDate={toDateISO}
                isLoading={status === "loading"}
              />
            )}
          </div>
        </div>

        <div className={clsx(styles.row, styles.row_align_left)}>
          <div
            className={clsx(styles.graph_item, styles.graph_item_align_left)}
          >
            <div className={styles.graph_content}>
              {clockedInEmployeesData.values && (
                <ClockedInEmployeesWidget
                  data={clockedInEmployeesData}
                  startDate={fromDateISO}
                  endDate={toDateISO}
                  isLoading={status === "loading"}
                />
              )}
            </div>
          </div>
          <div
            className={clsx(styles.graph_item, styles.graph_item_align_left)}
          >
            <div className={styles.graph_content}>
              {topSellingItemsData.values && (
                <TopSellingItemsWidget
                  data={topSellingItemsData}
                  startDate={fromDateISO}
                  endDate={toDateISO}
                  isLoading={status === "loading"}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.links}>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Home Page</span>
            <Link to="/business/information" className={styles.link}>
              https://somewebsite.com
            </Link>
          </div>
          <div className={styles.link_wrapper}>
            <span className={styles.page_name}>Menu Page</span>
            <Link to="/menu/items" className={styles.link}>
              https://anotherwebsite.com
            </Link>
          </div>
        </div>
        <div className={styles.qr_code_wrapper}>
          <div className={styles.qr_code}>
            <img src={qrCode} alt="QR Code" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
