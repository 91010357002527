import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import {
  getPrinters,
  getPrinter,
  addPrinter,
  updatePrinter,
  deletePrinter,
  updatePrinterStatus,
} from '../api/printers';

import { addNotification } from './tooltipSlice';

// Thunks
export const fetchPrinters = createAsyncThunk('printers/fetchPrinters', async ({ token }) => {
  const response = await getPrinters(token);
  return response.data;
});

export const fetchPrinter = createAsyncThunk('printers/fetchPrinter', async ({ id, token }) => {
  const response = await getPrinter(id, token);
  return response.data;
});

export const addNewPrinter = createAsyncThunk(
  'printers/addNewPrinter',
  async ({ newPrinterDetails, token }, { dispatch }) => {
    try {
      const response = await addPrinter(newPrinterDetails, token);
      dispatch(
        addNotification({
          message: 'Printer successfully created',
          status: 'succeeded',
        }),
      );
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to create new printer, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingPrinter = createAsyncThunk(
  'printers/updateExistingPrinter',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updatePrinter(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Printer successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update printer, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const removeExistingPrinter = createAsyncThunk(
  'printers/removeExistingPrinter',
  async ({ id, deleteDetails, token }, { dispatch }) => {
    try {
      await deletePrinter(id, deleteDetails, token);
      dispatch(
        addNotification({
          message: 'Printer successfully deleted',
          status: 'succeeded',
        }),
      );
      return id;
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to delete printer, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

export const updateExistingPrinterStatus = createAsyncThunk(
  'printers/updateExistingPrinterStatus',
  async ({ id, updatedDetails, token }, { dispatch }) => {
    try {
      await updatePrinterStatus(id, updatedDetails, token);
      dispatch(
        addNotification({
          message: 'Printer status successfully updated',
          status: 'succeeded',
        }),
      );
      return { id, updatedDetails };
    } catch (error) {
      dispatch(
        addNotification({
          message: `Failed to update printer status, ${error?.message}`,
          status: 'failed',
        }),
      );
      throw error;
    }
  },
);

const initialState = {
  printers: {},
  currentPrinter: {},
  queryDetails: {},
  status: 'idle',
  error: null,
};

const printersSlice = createSlice({
  name: 'printers',
  initialState,
  reducers: {
    setQueryDetails(state, action) {
      state.queryDetails = {
        ...state.queryDetails,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    const setLoading = (state) => {
      state.status = 'loading';
    };

    const setSucceeded = (state) => {
      state.status = 'succeeded';
    };

    const setFailed = (state, action) => {
      state.status = 'failed';
      state.error = action?.error.message;
    };

    builder
      .addCase(fetchPrinters.pending, setLoading)
      .addCase(fetchPrinters.fulfilled, (state, action) => {
        setSucceeded(state);
        state.printers = action.payload;
      })
      .addCase(fetchPrinters.rejected, setFailed)

      .addCase(fetchPrinter.pending, setLoading)
      .addCase(fetchPrinter.fulfilled, (state, action) => {
        setSucceeded(state);
        state.currentPrinter = action.payload;
      })
      .addCase(fetchPrinter.rejected, setFailed)

      .addCase(addNewPrinter.pending, setLoading)
      .addCase(addNewPrinter.fulfilled, (state, action) => {
        setSucceeded(state.printers);
        state.printers = action.payload;
      })
      .addCase(addNewPrinter.rejected, setFailed)

      .addCase(updateExistingPrinter.pending, setLoading)
      .addCase(updateExistingPrinter.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;
        const index = state.printers.findIndex((printer) => printer.id === updatedDetails.id);

        if (index !== -1) {
          state.printers[index] = updatedDetails;
        }
      })
      .addCase(updateExistingPrinter.rejected, setFailed)

      .addCase(removeExistingPrinter.fulfilled, (state, action) => {
        setSucceeded(state);
        const id = action.payload;

        const metaDetails = action.meta.arg.deleteDetails.change_receipt_type;
        const categoryDetails =
          metaDetails === 'Kitchen'
            ? 'kitchen_receipt_printers'
            : metaDetails === 'Customer'
              ? 'customer_receipt_printers'
              : 'KitchenAndCustomer';
        if (!metaDetails) {
          return;
        }

        if (categoryDetails === 'KitchenAndCustomer') {
          state.printers.kitchen_receipt_printers = state.printers.kitchen_receipt_printers.filter(
            (printer) => printer.id !== id,
          );
          state.printers.customer_receipt_printers = state.printers.customer_receipt_printers.filter(
            (printer) => printer.id !== id,
          );
        } else if (state.printers[categoryDetails]) {
          state.printers[categoryDetails] = state.printers[categoryDetails].filter((printer) => printer.id !== id);
        }
      })
      .addCase(removeExistingPrinter.pending, setLoading)
      .addCase(removeExistingPrinter.rejected, setFailed)

      .addCase(updateExistingPrinterStatus.pending, setLoading)
      .addCase(updateExistingPrinterStatus.fulfilled, (state, action) => {
        setSucceeded(state);
        const { updatedDetails } = action.payload;

        Object.entries(state.printers).forEach(([key, value]) => {
          state.printers[key] = value.map((printer) => {
            if (printer.id === updatedDetails.id) {
              return {
                ...printer,
                ...updatedDetails,
              };
            }
            return printer;
          });
        });
      })
      .addCase(updateExistingPrinterStatus.rejected, setFailed);
  },
});

export const { setQueryDetails } = printersSlice.actions;
export default printersSlice.reducer;
