import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAppContext } from "../../../../context/context";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import moment from "moment-timezone";

import {
  fetchBusinessInformation,
  updateExistingBusinessInformation,
} from "../../../../redux/informationSlice";

import PageTitle from "../../../utilities/page-title/page-title";
import Input from "../../../utilities/input/input";
import Selector from "../../../utilities/selector/selector";
import Button from "../../../utilities/button/button";

import { registrationSchema } from "./validation";

import { IoIosArrowDown } from "react-icons/io";
import { FaPlus } from "react-icons/fa";

import styles from "./information.module.scss";

const timezones = moment.tz.names().map((timezone) => {
  const offset = moment.tz(timezone).format("Z");
  return {
    label: `(GMT${offset}) ${timezone}`,
    value: `(GMT${offset}) ${timezone}`,
  };
});

const valueConverter = (value, type) => {
  return type === "number" ? parseFloat(value) : value;
};

function Information() {
  // Hooks
  const dispatch = useDispatch();
  const { token, locationId } = useAppContext();

  // Store
  const business_information = useSelector(
    (state) => state.business_information.business_information
  );

  // Google Places API
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyC0mWJ-DJCIYIjFYghXTBOQLK6pqIWF9T8",
    libraries: ["places"],
  });
  const countryAutocompleteRef = useRef(null);

  // Local state
  const [businessInformation, setBusinessInformation] = useState({}); // Name, Phone, Email and Website
  const [timeZone, setTimeZone] = useState(""); // TimeZone
  const [address, setAddress] = useState(""); // Address
  const [businessLogoContent, setBusinessLogoContent] = useState(""); // Logo in Base64
  const [businessLogoFileName, setBusinessLogoFileName] = useState(""); // Logo format

  // Validation state
  const [errors, setErrors] = useState({});

  // Effects
  useEffect(() => {
    if (token) {
      dispatch(fetchBusinessInformation(token, locationId));
    }
  }, [dispatch, token, locationId]);

  useEffect(() => {
    if (business_information && business_information.length > 0) {
      const restaurant_information = business_information.find(
        (item) => item.business_id === locationId
      );

      if (restaurant_information) {
        const {
          business_address = {},
          business_details = {},
          logo = {},
        } = restaurant_information;

        setBusinessInformation({
          name: business_details.name || "",
          phone: business_details.phone_number || "",
          email: business_details.email || "",
          website: business_details.website || "",
        });
        setTimeZone(business_details.timezone ? business_details.timezone : "");
        setAddress(business_address.country ? business_address.country : "");
        setBusinessLogoContent(logo.content ? logo.content : "");
        setBusinessLogoFileName(logo.file_name ? logo.file_name : "");
      }
    }
  }, [business_information, locationId]);

  // Handlers
  const handlePlaceSelect = (autocompleteRef, setState) => {
    // Google Places API autocomplete
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (place) {
        setState(place.formatted_address || place.name);
      }
    }
  };

  const handleFileChange = (event) => {
    // Logo Select
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64String = reader.result;

        setBusinessLogoContent(base64String);
        setBusinessLogoFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleLogoClick = () => {
    // Logo view changed
    document.getElementById("logoInput").click();
  };

  const handleInputChange = (e, type) => {
    const { name, value } = e.target;
    setBusinessInformation((prev) => ({
      ...prev,
      [name]: valueConverter(value, type),
    }));
  };

  const handleUpdate = async (e) => {
    // Update business details
    e.preventDefault();

    const businessData = {
      business_id: locationId,
      business_details: {
        name: businessInformation.name,
        phone_number: businessInformation.phone,
        email: businessInformation.email,
        website: businessInformation.website,
        timezone: timeZone,
      },
      business_address: {
        country: address,
      },
      logo: {
        file_name: businessLogoFileName,
        content: businessLogoContent,
      },
    };

    const result = registrationSchema.safeParse(businessInformation);

    if (!result.success) {
      const errorMessages = result.error.format();
      setErrors(errorMessages);
    } else {
      setErrors({});

      try {
        await dispatch(
          updateExistingBusinessInformation({
            id: locationId,
            updatedDetails: businessData,
            token,
          })
        ).unwrap();
      } catch {
        console.log("An error occurred while sending data to the server");
      }
    }
  };

  if (!isLoaded) return <div>Loading Google Maps...</div>;

  return (
    <>
      <div className={styles.information}>
        <PageTitle name={"Business Information"} />
        <div className={styles.wrapper}>
          <form className={styles.form}>
            <div className={styles.main_info}>
              <h3 className={styles.subtitle}>Restaurant Details</h3>
              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Restaurant Name<span>*</span>
                </label>
                <Input
                  value={businessInformation.name}
                  onChange={handleInputChange}
                  name={"name"}
                  placeholder="Your restaurant name"
                  errors={errors.name?._errors}
                />
              </div>
              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Restaurant Phone Number<span>*</span>
                </label>
                <Input
                  value={businessInformation.phone}
                  onChange={handleInputChange}
                  name={"phone"}
                  placeholder="Your restaurant phone number"
                  errors={errors.phone?._errors}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Restaurant Email Address<span>*</span>
                </label>
                <Input
                  value={businessInformation.email}
                  onChange={handleInputChange}
                  name={"email"}
                  placeholder="Email address"
                  errors={errors.email?._errors}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>Restaurant Website</label>
                <Input
                  value={businessInformation.website}
                  onChange={handleInputChange}
                  name={"website"}
                  placeholder="Restaurant website URL"
                  errors={errors.website?._errors}
                />
              </div>

              <div className={styles.section} style={{ marginBottom: "24px" }}>
                <label>
                  Time Zone<span>*</span>
                </label>
                <Selector
                  placeholder={timeZone}
                  icon={IoIosArrowDown}
                  items={timezones}
                  selectedItem={timeZone}
                  onSelectItem={setTimeZone}
                  className={styles.selector}
                  style={{ color: "#959a9e" }}
                />
              </div>
              <h4 className={styles.subtitle}>Restaurant Address</h4>

              <div className={styles.section}>
                <Autocomplete
                  onLoad={(autocomplete) =>
                    (countryAutocompleteRef.current = autocomplete)
                  }
                  onPlaceChanged={() =>
                    handlePlaceSelect(countryAutocompleteRef, setAddress)
                  }
                  className={styles.autocomplete}
                >
                  <input
                    type="text"
                    placeholder="Country"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Autocomplete>
              </div>
              <Button
                className={styles.submitButton}
                title={"UPDATE"}
                onClick={handleUpdate}
              />
            </div>

            <div className={styles.section}>
              <div className={styles.add_logo}>
                <div
                  className={styles.logo_container}
                  onClick={handleLogoClick}
                >
                  {businessLogoContent ? (
                    <img
                      src={businessLogoContent}
                      alt="Logo Preview"
                      className={styles.logo_image}
                    />
                  ) : (
                    <FaPlus className={styles.plus_icon} />
                  )}
                </div>
                <div className={styles.logo_footer} onClick={handleLogoClick}>
                  <p>Add Logo</p>
                </div>
                <input
                  type="file"
                  id="logoInput"
                  style={{ display: "none" }}
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Information;
